<template>
  <div id="page-container">
    <div class="print-container A4 text-center">
      <img
        src="../assets/logo2.png"
        style="width: 38%; display: block; margin-top: 22px"
        class="imgprint"
        alt=""
      />
      <p
        style="text-align: left;padding-left: 102px;margin-top: -16px;"
        class="logotext"
      >
        Im Panoramahaus Dornbirn
      </p>
      <br />
      <h1
        style="
            margin-top: 40px;
            font-weight: bold;
            color: black;
            font-size: 3rem;
          "
        class="logoh1"
      >
        Gutschein-Gift Voucher
      </h1>

      <br />
      <img
        src="../assets/22.png"
        style="
            width: 100%;
            display: block;
            margin-top: 22px;
            height: 300px;
          "
        alt=""
      />

      <br />
      <br />
      <br />
      <p
        class="text-left price"
        style="
        font-size: 2.5rem;
            color: black;
            font-weight: bold;
            margin-top: 32px;
            padding: 0 5px;
          "
      >
        Wertgutschein: {{ body.price }},00 €
      </p>

      <br />
      <br />
      <div
        class="text-left date"
        style="
            font-size: 1.5rem;
            color: black;
            font-weight: bold;
            margin-top: 32px;
            padding: 0 5px;
          "
      >
        <div style="display: inline-block;width: 50%;">
          <span
            style="margin-right: 20px; font-size: 1.5rem; font-weight: bold;"
          >
            Nr: {{ $codePadding(body.id) }}</span
          >
        </div>
        <div style="display: inline-block;width: 50%;text-align: end;">
          Datum: {{ $durationFormat(body.createdAt) }}
        </div>
      </div>
      <p
        class="text-left text"
        style="
            font-size: 1.5rem;
            color: black;
            line-height: 31px;
            margin-top: 40px;
            padding: 0 5px;
            font-family: Courgette, cursive;
          "
      >
        Lassen Sie sich in angenehmer Atmosphäre verwöhnen. Wir freuen uns auf
        Ihren Besuch. Gerne können Sie per E-Mail, telefonisch oder direkt auf
        unserer Homepage einen Termin vereinbaren.

        <br />
        <br />
        <br />
        Let yourself be pampered in a pleasant atmosphere. We are looking
        forward to your visit. You are welcome to make an appointment by e-mail,
        by telephone or directly on our Homepage.
      </p>
      <br />
      <br />
      <!-- <br />
      <br />
      <br />
      <br /> -->
      <!-- <img
          src="../../../assets/33.png"
          style="
            width: 90%;
            margin: 0 auto;
            display: block;
            margin-top: 22px;
            height: 100px;
          "
          alt=""
        /> -->
      <div
        class="text-left"
        style="
            font-size: 1rem;
            color: black;
            margin: 38px 5px;
            background-color: #9e7500;
            margin-bottom: 0;
            color: #fff;

          "
      >
        <div style="border-bottom: #b5963d solid 1px;">
          <div style="width: 70%; padding-left: 12px;display: inline-block">
            Kontakt:
            <span style="color: #fff;margin-left: 7px;">
              +43 5572 3888 562
            </span>
          </div>
          <div style="width: 30%; display: inline-block;text-align: center;">
            Adresse:
          </div>
        </div>
        <div style="border-bottom: #b5963d solid 1px;">
          <div style="width: 70%;padding-left: 12px; display: inline-block">
            Mobil:
            <span style="color: #fff;margin-left: 25px;">
              +43 (0) 660 10 2 10 10
            </span>
          </div>
          <div style="width: 30%; display: inline-block;text-align: center;">
            Messestraße 1
          </div>
        </div>
        <div style="border-bottom: #b5963d solid 1px;">
          <div style="width: 70%;padding-left: 12px; display: inline-block">
            Email:
            <span style="color: #fff;margin-left: 8px;">
              info@kleopatramassagen-beauty.com
            </span>
          </div>
          <div style="width: 30%; display: inline-block;text-align: center;">
            6850 Dornbirn
          </div>
        </div>
        <div style="border-bottom: #b5963d solid 1px;">
          <div style="width: 70%;padding-left: 12px; display: inline-block">
            Instagram:
            <span style="color: #fff;margin-left: 8px;">
              kleopatramassage-beauty.com
            </span>
          </div>
          <div style="width: 30%; display: inline-block;text-align: center;">
            Austria
          </div>
        </div>
        <div style="border-bottom: #b5963d solid 1px;">
          <div style="width: 100%;padding-left: 12px; display: inline-block">
            https://kleopatramassage-beauty.com
          </div>
        </div>
      </div>
      <p>
        Gutschein gültig 1 Jahr ab Ausstellungsdatum. Barablöse ist nicht
        möglich
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        // email: null,
        inhomepage: 0,
        price: 0,
        id: 0,
        createdAt: "",
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
  
    
  },
  created() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.$http.get(`gifts/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
        },
        (err) => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("Error"),
            text: err.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      );
    }
  },
};
</script>

<style scoped>
#page-container {
  background-color: #9e9e9e;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=");
  -webkit-transition: left 500ms;
  transition: left 500ms;
}

.print-container {
  background-color: #fff;
  clear: both;
  /*Do something here with A4*/
  padding: 0px 40px 0px 40px;
  z-index: 0;
  /* height: 100vh; */
}

.A4 {
  background: white;
  width: 1020px;
  /* height: 29.7cm; */
  display: block;
  margin: 0 auto;
  padding: 10px 110px;
  /* margin-bottom: 0.5cm; */
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  box-sizing: border-box;

  font-family: Arial, Helvetica, sans-serif;
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }

  /* size : A4 portrait; */

  body {
    margin: 0;
    padding: 0;
  }

  .A4 {
    box-shadow: none;
    margin: 0;
    width: auto;
    height: auto;
  }
}
.grecaptcha-badge {
  display: none;
}

@media only screen and (max-width: 900px) {
  .A4 {
    background: white;
    width: 100vw;
    display: block;
    margin: 0 auto;
    padding: 10px 10px;
    box-shadow: none;
  }
  .imgprint {
    margin: auto;
  }
  .logotext {
    padding-left: 41vw !important;
  }
  .logoh1 {
    margin-top: 0 !important;
    font-size: 2rem !important;
  }
  .imgpage {
    margin-top: 0 !important;
    height: auto !important;
  }

  .price {
    font-size: 1.5rem !important;

    margin-top: 0 !important;
  }
  .date {
    font-size: 1rem !important;
    margin-top: 0 !important;
  }
  .date span {
    font-size: 1rem !important;
  }
  .text {
    font-size: 1rem !important;
    line-height: 25px !important;
    margin-top: 25px !important;
  }
}
</style>
